.circularStrategy {
  font-family: PingFangSC-Regular;
}
.circularStrategy .content {
  padding: 0.25rem;
}
.circularStrategy .content .csTitle {
  font-family: PingFangSC-Medium;
  color: #333;
  font-size: 0.28rem;
  font-weight: bold;
  height: 0.63rem;
  display: flex;
  align-items: center;
  padding-bottom: 0.25rem;
  margin-bottom: 0.2rem;
  border-bottom: 0.01rem solid rgba(211, 217, 222, 0.49);
}
.circularStrategy .content .csTitle span {
  font-size: 0.24rem;
  transform: scale(0.83);
  padding: 0 0.1rem;
  line-height: 0.36rem;
  background: #f1f7ff;
  border-radius: 0.04rem;
  display: inline-block;
  margin-left: 0.05rem;
  color: #5569FC;
}
.circularStrategy .content .csTitle span.gray {
  color: #9b9b9b;
  background: #f5f5f5;
}
.circularStrategy .content .csTitle span.gray1 {
  padding: 0.02rem 0.13rem;
  border: none;
  color: #f5465d;
  background: rgba(245, 70, 93, 0.1);
}
.circularStrategy .content .csTitle span.gray2 {
  padding: 0.02rem 0.13rem;
  border: none;
  color: #32bf88;
  background: rgba(50, 191, 136, 0.09);
}
.circularStrategy .strategy .strategyItem {
  display: flex;
  flex-direction: column;
  padding: 0.1rem 0.2rem;
  margin-bottom: 0.1rem;
}
.circularStrategy .strategy .strategyItem:last-child {
  padding-bottom: 0.2rem;
  margin-bottom: 0;
}
.circularStrategy .strategy .strategyItem:nth-of-type(3) {
  padding-left: 0.5rem;
  padding-right: 0;
}
.circularStrategy .strategy .strategyItem:nth-of-type(6) {
  padding-left: 0.5rem;
  padding-right: 0;
}
.circularStrategy .strategy .xian {
  padding: 0.01rem 0.2rem;
  border-top: 0.01rem solid #f5f5f5;
}
.circularStrategy .strategy .strategyItem1 {
  padding: 0.2rem 0.2rem 0.2rem 0.2rem;
  display: flex;
  align-items: center;
}
.circularStrategy .strategy .strategyItem1 .span1 {
  font-size: 0.28rem;
  color: #6F7989;
  margin-right: 0.12rem;
}
.circularStrategy .strategy .span1 {
  font-size: 0.24rem;
  color: #6F7989;
  margin-right: 0.12rem;
}
.circularStrategy .strategy .span2 {
  font-size: 0.28rem;
  font-weight: 550;
  color: #333333;
}
.circularStrategy .strategy .span3 {
  font-size: 0.28rem;
  color: #6F7989;
  margin-right: 0.2rem;
}
.circularStrategy .strategy .span4 {
  font-size: 0.28rem;
  color: #32BF88;
  font-weight: 550;
  margin-right: 0.2rem;
}
.circularStrategy .action .actionItem {
  padding-top: 0.1rem;
  text-align: center;
}
.circularStrategy .action .actionItem img {
  height: 0.6rem;
  display: block;
  margin: auto;
}
.circularStrategy .action .actionItem p {
  margin-top: 0.12rem;
  font-size: 0.24rem;
  color: #666666;
}
.circularStrategy .action .actionButton {
  height: 0.79rem;
  width: 69%;
  margin: 1rem auto 0;
  background: #ff4b04;
  border-radius: 100px;
  font-size: 0.31rem;
  font-weight: bold;
  color: #ffffff;
  line-height: 0.78rem;
  text-align: center;
}
.err_msg {
  width: 100%;
  height: 0.4rem;
  line-height: 0.4rem;
  background-color: rgba(245, 70, 93, 0.1);
  color: #f5465d;
  font-size: 0.24rem;
  text-align: center;
}
.msg_list {
  padding: 0.2rem 0.3rem 0.25rem 0.3rem;
}
.layoutBox {
  min-height: calc(100vh - 1.76rem);
}
.head_list {
  display: flex;
  justify-content: space-between;
}
.head_list .numItem {
  width: 50%;
  margin-left: 0.2rem;
  text-align: left;
  margin-bottom: 0.3rem;
}
.head_list .numItem p {
  color: #666666;
  font-size: 0.24rem;
  margin-bottom: 0.1rem;
}
.head_list .numItem span {
  color: #373737;
  font-size: 0.32rem;
}
.head_list .numItem1 {
  width: 50%;
  margin-left: 0.2rem;
  text-align: left;
  margin-bottom: 0.3rem;
}
.head_list .numItem1 p {
  color: #6f7a8b;
  font-size: 0.24rem;
  margin-bottom: 0.1rem;
}
.head_list .numItem1 span {
  color: #333333;
  font-size: 0.32rem;
}
.head_list .numItem2 {
  width: 25%;
  margin-left: 0.2rem;
  text-align: left;
  margin-bottom: 0.3rem;
}
.head_list .numItem2 p {
  color: #6f7a8b;
  font-size: 0.24rem;
  margin-bottom: 0.1rem;
}
.head_list .numItem2 span {
  color: #333333;
  font-size: 0.32rem;
}
.head_list .numItem3 {
  width: 40%;
  text-align: left;
  margin-bottom: 0.3rem;
}
.head_list .numItem3 p {
  color: #6f7a8b;
  font-size: 0.24rem;
  margin-bottom: 0.1rem;
}
.head_list .numItem3 span {
  color: #333333;
  font-size: 0.32rem;
}
.csTitle1 {
  border-top: 0.01rem solid rgba(211, 217, 222, 0.5);
  padding: 0.2rem 0 0 0;
  color: #333333;
  font-size: 0.28rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}
.csTitle1 span {
  font-weight: bold;
}
.csTitle2 {
  font-family: PingFangSC-Medium;
  padding-bottom: 0.2rem;
  color: #333333;
  font-size: 0.28rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin-bottom: 0.2rem;
}
.csTitle2 span {
  font-weight: bold;
}
.contWhiteBox {
  border-radius: 0.16rem;
}
.comBtnStyle {
  font-family: Regular;
  background: linear-gradient(131deg, #527EFF 0%, #5569FC 100%);
  color: #FFFFFF;
  font-size: 0.32rem;
}
